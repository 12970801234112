<template>
  <div>
    <cdv-search-data-search />
  </div>
</template>

<script>
const CdvSearchDataSearch = () => import('./CdvSearchDataSearch.vue');

export default {
  name: 'CdvSearchData',
  components: {
    CdvSearchDataSearch,
  },
  props: {
    data: Object,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
